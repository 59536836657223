<template>
  <v-container>
    <div class="ml-2">
      <PageTitle class="ft" text="Lube Order Request" />
    </div>
    <div class="buttonText ml-1" id="result">
      <div class="d-flex flex-row flex-grow-1">
        <v-btn
          v-ripple="{ class: 'primary--text' }"
          color="#04a9f3"
          rounded
          fab
          icon
          small
          class="ml-2 mt-3 white--text ft"
          style="font-size: 12px"
          @click="$router.go(-1)"
        >
          <i class="material-icons-outlined primary--text">arrow_back</i>
        </v-btn>
        <div class="black--text mt-5 ml-3 absolute ft">Go Back</div>
      </div>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <v-card class="elevation-1 pt-5 pb-4" style="border-radius: 8px;">
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(makeRequest)" class="px-3">
                <v-col>
                  <v-row>
                    <div class="d-flex flex-column flex-fill">
                      <div
                        class="d-flex flex-column flex-fill pa-4 ma-1 "
                        style="background-color: #eeeeee; border-radius: 6px;"
                      >
                        <v-card
                          v-for="(row, index) in orderLineItems"
                          :key="index"
                          class="elevation-0 d-flex flex-column flex-fill mt-2 pt-6 mb-2 px-2"
                          style="width:100%;"
                        >
                          <div class="d-flex flex-row flex-grow-1">
                            <ValidationProvider
                              tag="div"
                              class="d-flex flex-row flex-grow-1 ma-2"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <v-autocomplete
                                :items="products"
                                v-model="row.productId"
                                item-text="name"
                                item-value="id"
                                :readonly="isOrderApproved || isOrderReviewed"
                                prepend-inner-icon="category"
                                :error-messages="errors[0]"
                                outlined
                                label="Choose A Product"
                                hint="Select from a list of product "
                                class="ft font-weight-medium text-sm"
                              />
                            </ValidationProvider>
                            <ValidationProvider
                              tag="div"
                              rules="required|numeric"
                              v-slot="{ errors }"
                              class="d-flex flex-row flex-grow-1 ma-2"
                            >
                              <v-text-field
                                outlined
                                v-model="row.quantityLoaded"
                                prepend-inner-icon="all_inclusive"
                                label="Enter Quantity Loaded"
                                :readonly="isOrderApproved || isOrderReviewed"
                                :error-messages="errors[0]"
                                class="ft font-weight-medium text-sm"
                              />
                            </ValidationProvider>
                          </div>
                          <div
                            v-if="orderLineItems.length > 1"
                            class="d-flex flex-row flex-grow-1 justify-end"
                          >
                            <v-btn
                              color="red"
                              small
                              disabled
                              text
                              @click="removeRow(index)"
                              class="mt-n3 mr-2 mb-2 ft font-weight-medium text-capitalize text-sm"
                            >
                              <i class="material-icons-outlined"
                                >remove_circle</i
                              >
                              <span class="pl-2">Remove</span>
                            </v-btn>
                          </div>
                        </v-card>
                      </div>
                      <div
                        class="d-flex flex-row flex-grow-1"
                        style="justify-content: flex-end"
                      >
                        <v-btn
                          small
                          text
                          outlined
                          disabled
                          @click.stop="addNewRow"
                          color="primary"
                          class="ft font-weight-medium text-sm mr-3 mt-2"
                        >
                          <i class="material-icons-outlined">add</i> Add Row
                        </v-btn>
                      </div>
                    </div>
                  </v-row>
                  <v-row class="mt-5 mr-1">
                    <div
                      class="d-flex flex-row flex-grow-1 flex-fill justify-end"
                    >
                      <v-btn
                        :disabled="
                          isOrderApproved || isOrderReviewed || isLoading
                        "
                        :loading="isLoading"
                        color="primary"
                        type="submit"
                        >Approve Request</v-btn
                      >
                    </div>
                  </v-row>
                </v-col>
              </v-form>
            </ValidationObserver>
          </v-card>
        </template>
      </v-col>
    </v-row>
    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import PageTitle from "../../../components/PageTitle";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import axios from "axios";

extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
export default {
  name: "LubeOrderRequest",
  components: {
    PageTitle,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      isLoading: false,
      isPageLoading: false,
      products: [],
      referenceNumber: "",
      tripNumber: "",
      vehicleRegistrationNumber: "",
      driverName: "",
      driverId: "",
      customerName: "",
      carrierSignature: "",
      isOrderApproved: false,
      isOrderReviewed: false,
      orderLineItems: [
        {
          productId: "",
          quantityLoaded: 0,
          uom: "",
          quantity: 0
        }
      ],
      showSnackBar: false,
      message: "",
      status: ""
    };
  },
  methods: {
    async getProducts() {
      try {
        this.isPageLoading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_BASE}/products/lubes/list?paginated=false`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        if (response) {
          this.isPageLoading = false;
          this.products = response.data;
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to get lubes list";
        this.status = "red";
        this.isPageLoading = false;
      }
    },
    async requestDetails() {
      try {
        this.isPageLoading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_BASE}/orders/lubes/${this.$route.params.id}/request/details`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        if (response) {
          const {
            referenceNumber,
            lubesOrdersLineItem,
            vehicleRegistrationNumber,
            tripNumber,
            isOrderApproved,
            isOrderReviewed
          } = response.data;
          this.vehicleRegistrationNumber = vehicleRegistrationNumber;
          this.tripNumber = tripNumber;
          this.referenceNumber = referenceNumber;
          this.isOrderApproved = isOrderApproved;
          this.isOrderReviewed = isOrderReviewed;
          this.orderLineItems = lubesOrdersLineItem.map(lineItem => {
            return {
              ...lineItem,
              productId: lineItem.productId.id
            };
          });

          this.isPageLoading = false;
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to get lubes list";
        this.status = "red";
        this.isPageLoading = false;
      }
    },
    async makeRequest() {
      try {
        this.isLoading = true;
        const response = await axios.put(
          `${process.env.VUE_APP_BASE}/orders/lubes/${this.$route.params.id}/approve/request`,
          null,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        if (response) {
          this.showSnackBar = true;
          this.message = "Lube request approved successfully";
          this.status = "success";
          setTimeout(() => {
            this.isLoading = false;
            this.$router.push({
              name: "master.lubes.orders.branch"
            });
          }, 3000);
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to approve request";
        this.status = "red";
        this.isLoading = false;
      }
    },
    addNewRow() {
      this.orderLineItems.push({
        productId: "",
        quantityLoaded: 0,
        uom: "",
        quantity: 0
      });
    },
    removeRow(index) {
      this.orderLineItems.splice(index, 1);
    }
  },
  created() {
    this.getProducts();
    this.requestDetails();
  }
};
</script>
